<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Logs</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header d-flex justify-content-between">
                                <h3 class="card-title">Logs</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>From Date</label>
                                            <input type="date" class="form-control form-control-sm" v-model="meta.from_date" @change="search" />
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>To Date</label>
                                            <input type="date" class="form-control form-control-sm" v-model="meta.to_date" @change="search" />
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label>User</label>
                                        <search
                                            :class="{ 'is-invalid': errors.user_id }"
                                            :customClass="{ 'is-invalid': errors.user_id }"
                                            :initialize="meta.user_id"
                                            id="user_id"
                                            label="name"
                                            placeholder="Select User"
                                            :data="users"
                                            @input="changeUser($event)"
                                        >
                                        </search>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>Log Type</label>
                                            <select class="form-control form-control-sm" v-model="meta.log_type" @change="search">
                                                <option value="">Select One</option>
                                                <option value="Download">Download</option>
                                                <option value="Upload">Upload</option>
                                                <option value="Report">Report</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>Status</label>
                                            <select class="form-control form-control-sm" v-model="meta.status" @change="search">
                                                <option value="">Select One</option>
                                                <option value="0">Failed</option>
                                                <option value="1">Success</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 mt-3">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Log Type</th>
                                                    <th>User</th>
                                                    <th>Created at</th>
                                                    <th class="text-center">Status</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(log,key) in logs" :key="key">
                                                    <td class="text-center">{{meta.from+key}}</td>
                                                    <td>{{ log.log_type }}</td>
                                                    <td>{{ log.user.name }}</td>
                                                    <td>{{ log.created_at }}</td>
                                                    <td class="text-center">
                                                        <span v-if="log.status==0" class="badge badge-danger">Failed</span>
                                                        <span v-else class="badge badge-success">Success</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <button class="btn btn-xs btn-success" @click="downloadLog(log)">
                                                            <i class="fa fa-eye"></i>
                                                        </button>
                                                        <!-- <button v-if="log.log_type=='Upload'" class="btn btn-xs btn-primary" @click="uploadLog(log)">
                                                            <i class="fa fa-upload"></i>
                                                        </button> -->
                                                    </td>
                                                </tr>
                                                <tr v-if="logs.length <= 0">
                                                    <td colspan="6" style="text-align: center;">No logs Found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    let Search = require("@/components/Search.vue").default;
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "logs",
        components: { Search, Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "desc",
                    keyword: "log_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    from_date: "",
                    to_date: "",
                    user_id: "",
                    status:"",
                    log_type:"",
                },
                users: [],
                logs: [],
                errors: [],
            };
        },

        mounted() {
            let vm = this;
            vm.getUsers();
            vm.index();
        },

        methods: {

            getUsers() {
                let vm = this;
                vm.$store
                .dispatch("post", { uri: "getUsers" })
                .then(function (response) {
                    vm.users = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            index() {
                let vm = this;
                vm.$store
                .dispatch("post", { uri: "paginateLogs", data: vm.meta })
                .then(function (response) {
                    vm.logs = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            changeUser(user_id) {
                let vm = this;
                if(Number.isInteger(user_id)) {
                    vm.meta.user_id = user_id;
                    vm.search()
                }
            },

            downloadLog(log)  {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "viewLog", data: log };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    alert(response.data.data.response)
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            uploadLog(log) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "uploadLog", data: log };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Record is successfully updated");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            reset() {
                let vm = this;
                vm.meta.from_date = "";
                vm.meta.to_date = "";
                vm.meta.user_id = "";
                vm.meta.status = "";
                vm.index();
            },
        },
    };
</script>
