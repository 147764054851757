<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>

                <li class="breadcrumb-item active">Keywords</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Keyword</h3>
                                <h3 class="card-title" v-else>Update Keyword</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Keyword<span class="text-danger"> *</span></label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.keyword}" placeholder="Keyword" v-model="keyword.keyword" ref="keyword" />
                                            <span v-if="errors.keyword" class="invalid-feedback">{{ errors.keyword[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Regional Word<span class="text-danger"> *</span></label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.regional_word}" placeholder="Regional Word" v-model="keyword.regional_word" />
                                            <span v-if="errors.regional_word" class="invalid-feedback">{{ errors.regional_word[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/keywords" class="btn btn-sm btn-outline-danger" type="button"><i class="fas fa-ban"></i> Discard</router-link>
                    
                                    <span v-if="status">
                                        <button class="btn btn-sm btn-outline-success" type="submit"><i class="fas fa-save"></i> Submit</button>
                                    </span>
                                    <span v-else>
                                        <button class="btn btn-sm btn-outline-success" type="submit"><i class="fas fa-save"></i> Update</button>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
   
    export default {
        name: "Keywords.Create",
        components: {},
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "keyword",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                keyword: {
                    keyword_id: "",
                    keyword: "",
                    regional_word: "",
                },
                keyword_language: {
                    keyword_id: "",
                    language_id: "",
                    value: "",
                },

                keywords: [],
                errors: [],
                keywordLanguages: [],
            };
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (to.name == "Keywords.Create") {
                    vm.$refs.keyword.focus();
                } else {
                    vm.status = false;
                    let uri = { uri: "viewKeyword", data: { keyword_id: to.params.keyword_id } };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.keyword = response.data.data;
                          
                            vm.$refs.keyword.focus();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            });
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateKeywords", data: vm.meta })
                    .then(function (response) {
                        vm.keywords = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                vm.index();
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                //  vm.keyword.branch_id = vm.$store.getters.user.branch_id;
                let uri = { uri: "addKeyword", data: vm.keyword };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Keyword is successfully created");
                        vm.$router.push("/keywords");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateKeyword", data: vm.keyword };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Keyword is successfully updated");
                        vm.$router.push("/keywords");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            deleteKeyword(role) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteKeyword", data: role };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Role is successfully deleted");
                            vm.discard();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
        },
    };
</script>
