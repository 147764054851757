import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueLoading);
app.use(CKEditor)

app.directive('can', {
    mounted: function (el, binding) {
        let permissions = store.getters.permissions
        if(permissions && permissions.length!=0) {
            let permission = permissions.filter(function (el) {
                return el.ability.ability == binding.value;
            });
            if (!permission[0]) {
                el.parentNode.removeChild(el);
            }
        }
    }
});
app.mount('#app');
