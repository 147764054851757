<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <a href="javascript:void(0)" class="brand-link" style="text-align:center">
            <img src="@/assets/logo.png" alt="Logo" class="brand-image" style="width: 150px;" />

            <!-- <span class="brand-text font-weight-light">M-Power</span> -->
        </a>

        <div class="sidebar">
            <div class="user-panel mt-3 pb-1 d-flex">
                <div class="image">
                    <img :src="$store.getters.user?.avatar" class="img-circle elevation-2" alt="User Image" />
                </div>

                <div class="info" style="margin-top: -5px;">
                    <a class="d-block text-light">{{ $store.getters.user.name }}</a>
                    <span class="text-light">
                        <span>{{ $store?.getters?.user?.role?.role }}</span>
                    </span>
                </div>
            </div>

            <nav class="mt-2" v-if="$store.getters.user?.role?.role=='Admin'">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item" v-can="'dashboard'">
                        <router-link to="/dashboard" class="nav-link">
                            <i class="nav-icon fas fa-home blue"></i>
                            <p>Dashboard</p>
                        </router-link>
                    </li>

                    <!-- <li class="nav-item" v-can="'roles'">
                        <router-link to="/roles" class="nav-link">
                            <i class="nav-icon fas fa-user-tag pink"></i>
                            <p>Roles</p>
                        </router-link>
                    </li> -->

                    <li class="nav-item" v-can="'users'">
                        <router-link to="/users" class="nav-link">
                            <i class="nav-icon fas fa-users purple"></i>
                            <p>Users</p>
                        </router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link to="/permissions" class="nav-link">
                            <i class="nav-icon fas fa-shield-alt blue"></i>
                            <p>Permissions</p>
                        </router-link>
                    </li> -->
                    <li class="nav-item" v-can="'projects'">
                        <router-link to="/projects" class="nav-link">
                            <i class="nav-icon fas fa-list yellow"></i>
                            <p>Projects</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-can="'category_types'">
                        <router-link to="/category_types" class="nav-link">
                            <i class="nav-icon fas fa-bezier-curve pink"></i>
                            <p>Category Types</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-can="'categories'">
                        <router-link to="/categories" class="nav-link">
                            <i class="nav-icon fas fa-vector-square indigo"></i>
                            <p>Category</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-can="'languages'">
                        <router-link to="/languages" class="nav-link">
                            <i class="nav-icon fas fa-language orange"></i>
                            <p>Languages</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-can="'forms'">
                        <router-link to="/forms" class="nav-link">
                            <i class="nav-icon fas fa fa-file teal"></i>
                            <p>Forms</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-can="'keywords'">
                        <router-link to="/keywords" class="nav-link">
                            <i class="nav-icon fas fa fa-columns voilet"></i>
                            <p>Keywords</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-can="'pages'">
                        <router-link to="/pages" class="nav-link">
                            <i class="nav-icon fas fa fa-book yellowgreen"></i>
                            <p>Pages</p>
                        </router-link>
                    </li>
                      
                    <li class="nav-item" v-can="'records'">
                        <router-link to="/records" class="nav-link">
                            <i class="nav-icon fas fa fa-folder purple"></i>
                            <p>Records</p>
                        </router-link>
                    </li>

                    <li class="nav-item" v-can="'records'">
                        <router-link to="/logs" class="nav-link">
                            <i class="nav-icon fas fa fa-folder red"></i>
                            <p>Logs</p>
                        </router-link>
                    </li>
                  
                    <li class="nav-item">
                        <a href="#" class="nav-link" @click.prevent="logout()">
                            <i class="nav-icon fas fa-lock cyan"></i>
                            <p>Logout</p>
                        </a>
                    </li>
                </ul>
            </nav>
            <nav class="mt-2" v-else>
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item" v-can="'dashboard'">
                        <router-link to="/dashboard" class="nav-link">
                            <i class="nav-icon fas fa-home blue"></i>
                            <p>Dashboard</p>
                        </router-link>
                    </li>

                    
                    <li class="nav-item" v-can="'records'">
                        <router-link to="/records" class="nav-link">
                            <i class="nav-icon fas fa fa-folder purple"></i>
                            <p>Records</p>
                        </router-link>
                    </li>
                  
                    <li class="nav-item">
                        <a href="#" class="nav-link" @click.prevent="logout()">
                            <i class="nav-icon fas fa-lock cyan"></i>
                            <p>Logout</p>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </aside>
</template>

<script>
    export default {
        name: "Proj-Menu",
        data() {
            return {
                ds: "",
                total_records: 0,
            };
        },
        mounted(){
        },
        methods: {
            getCount() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "count" })
                    .then(function (response) {
                        vm.total_records = response.data["records"];
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            logout() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "logout", data: vm.$store.getters.user })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("logout");
                        vm.$router.push("/login");
                        location.reload();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>

<style scoped>
    .hh:hover {
        color: white !important;
    }
    .img1 {
        width: 210px;
        margin-left: 0px;
    }
   
</style>
