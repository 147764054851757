import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store/index.js';

// Authentication
import Login from '@/views/auth/Login.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import Dashboard from '@/views/Dashboard.vue'
import Profile from '@/views/auth/Profile.vue'

//configuration
import Roles from '@/views/role/Role.vue'



//Users
import Users from '@/views/user/Index.vue'
import CreateUser from '@/views/user/Create.vue'

// language
import Languages from '@/views/language/Language.vue'

// projects
import Projects from '@/views/project/Project.vue'

// category type
import CategoryTypes from '@/views/categoryType/CategoryTypes.vue'

// category 
import Categories from '@/views/category/Category.vue'

// form
import Forms from '@/views/form/Index.vue'
import CreateForm from '@/views/form/Create.vue'


// keyword
import Keywords from '@/views/keyword/Index.vue'
import CreateKeyword from '@/views/keyword/Keyword.vue'

// records
import Records from '@/views/Records.vue'

// pages
import Pages from '@/views/pages/pages.vue'

// permissions
import Permissions from '@/views/Permissions.vue'

// records
import Logs from '@/views/log/Index.vue'

//Keywords


const routes = [

	// Authentication
	{ 
		path: '/login', name: 'Login', 
		component: Login,  meta: { unAuth: true }
	},
	{ 
		path: '/forgot-password', name: 'ForgotPassword.Index', 
		component: ForgotPassword,  meta: { unAuth: true }  
	},
	{ 
		path: '/reset-password', name: 'ResetPassword.Index', 
		component: ResetPassword,  meta: { unAuth: true}  
	},
	{ 
		path: '/', name: 'Home', 
		component: Dashboard,  meta: { auth: true, permission:'All' }  
	},
    { 
		path: '/dashboard', name: 'Dashboard', 
		component: Dashboard,  meta: { auth: true, permission:'All'  }  
	},
	{ 
		path: '/profile', name: 'Profile', 
		component: Profile,  meta: { auth: true, permission:'All' }  
	},
	{ 
		path: '/roles', name: 'Role', 
		component: Roles,  meta: { auth: true, permission:'Admin' }  
	},

	// Users
	{ 
		path: '/users', name: 'Users.Index', 
		component: Users,  meta: { auth: true, permission:'Admin' }  
	},
	{ 
		path: '/users/create', name: 'Users.Create', 
		component: CreateUser,  meta: { auth: true, permission:'Admin' } 
	},
	{ 
		path: '/users/:user_id/edit', name:'Users.Edit', 
		component:CreateUser,  meta: { auth: true, permission:'Admin' } 
	},

	// language
	{ 
		path: '/languages', name: 'Language', 
		component: Languages,  meta: { auth: true, permission:'Admin'}  
	},
	// project
	{ 
		path: '/projects', name: 'Project', 
		component: Projects,  meta: { auth: true, permission:'Admin'}  
	},

	// category type
	{ 
		path: '/category_types', name: 'CategoryType',  
		component: CategoryTypes,  meta: { auth: true, permission:'Admin'}  
	},

	// category
	{ 
		path: '/categories', name: 'Category',  
		component: Categories,  meta: { auth: true, permission:'Admin'}  
	},

	// form
	{ 
		path: '/forms', name: 'Forms.Index', 
		component: Forms,  meta: { auth: true, permission:'Admin'}  
	},
	{ 
		path: '/form/create', name: 'Forms.Create', 
		component: CreateForm,  meta: { auth: true, permission:'Admin'}  
	},
	{ 
		path: '/forms/:form_id/edit', name:'Forms.Edit', 
		component:CreateForm,  meta: { auth: true, permission:'Admin' } 
	},
	// keyword
	{ 
		path: '/keywords/create', name: 'Keywords.Create', 
		component: CreateKeyword,  meta: { auth: true, permission:'Admin'}  
	},
	{ 
		path: '/keywords', name: 'Keywords.Index', 
		component: Keywords,  meta: { auth: true, permission:'Admin'}  
	},
	{ 
		path: '/keywords/:keyword_id/edit', name:'Keywords.Edit', 
		component:CreateKeyword,  meta: { auth: true, permission:'Admin' } 
	},

	// records
	{ 
		path: '/records', name: 'Record', 
		component: Records,  meta: { auth: true, permission:'All' }  
	},

	// page
	{ 
		path: '/pages', name: 'Page', 
		component: Pages,  meta: { auth: true, permission:'Admin'}  
	},
	
	//Permissions
	{ 
		path: '/permissions', name: 'Permissions', 
		component: Permissions,  meta: { auth: true, permission:'Admin' }  
	},
	// records
	{ 
		path: '/logs', name: 'Log', 
		component: Logs,  meta: { auth: true, permission:'Admin'}  
	},

]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach(function(to, _, next) {
	if (to.meta.auth && !store.getters.user) {
		next('/login');
	}
	
	else if(to.meta.unAuth && store.getters.user) {
		next('/dashboard');
	}
	else {
		if(store.getters.user?.role?.role=='User' && to.meta.permission=='Admin') {
			next('/records');
		}
		else {
			next();
		}
    } 
});

export default router