<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
               
                <li class="breadcrumb-item active">Project</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Project</h3>
                                <h3 class="card-title" v-else>Update Project</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="role">Project<span class="text-danger"> *</span></label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.project}" placeholder="Project" v-model="project.project" ref="project" />
                                            <span v-if="errors.project" class="invalid-feedback">{{ errors.project[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="description">Description</label>
                                            <textarea type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.description}" placeholder="Description" v-model="project.description"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-danger" type="button" @click="discard()"><i class="fas fa-ban"></i> Discard</button>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Projects</h3>
                               
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" v-if="!meta.trashed"/>
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th @click="sort('project')">Project
                                                <span>
                                                    <i v-if="meta.keyword=='project' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='project' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>  
                                            </th>
                                            <th @click="sort('description')">Description
                                                <span>
                                                    <i v-if="meta.keyword=='description' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='description' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>  
                                            </th>
                                            <th class='text-center'>Status</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(project,key) in projects" :key="key">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{project.project}}</td>
                                            <td>{{project.description}}</td>
                                            <td class='text-center'> 
                                                <div class="card-tools">
                                                    <div class="custom-control custom-switch custom-switch-on-success custom-switch-off-danger">
                                                        <input type="checkbox" class="custom-control-input" :id="'project' + project.project_id" :checked="project.status" :value="project.status" @change="updateProjectStatus(project)" />
                                                        <label class="custom-control-label" :for="'project' + project.project_id"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Edit">
                                                    <i class="fas fa-pencil-alt" @click="edit(project)"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="projects.length <= 0">
                                            <td colspan="20" style="text-align: center;">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Project",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "project",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                project: {
                    project_id: "",
                    project: "",
                    description: "",
                
                },
                 projects: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            edit(project) {
                let vm = this;
                (vm.status = false), (vm.project = project), vm.$refs.project.focus();
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateProjects", data: vm.meta })
                    .then(function (response) {
                        vm.projects = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            updateProjectStatus(project) {
               
                let vm = this;
                project.status =  project.status == 1 ? 0 : 1;
            
                vm.$store
                    .dispatch("post", {uri:"deleteProject",data:project})
                    .then(function () {
                     
                    })
                    .catch(function (error) {
                      
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
          
            search(){
               let vm = this;
               vm.meta.page = 1;
               vm.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addProject", data: vm.project };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Project is successfully created");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            discard() {
                let vm = this;
                vm.project.project = "";
                vm.project.description = "";
                vm.errors = [];
                vm.status = true;
                vm.index();
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateProject", data: vm.project };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Project is successfully updated");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

          
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
