<template>
    <div class="hold-transition login-page">
        <div class="login-box">
            <div class="card">
                <div class="card-header text-center">
                    <img src="@/assets/logo.png" alt="Logo" height="80" /> <br>
                    <!-- <h4>M-POWER</h4> -->
                    
                </div>
                <div class="card-body">
                    <p class="login-box-msg">Sign in to start your session</p>
                    <form autocomplete="off" @submit.prevent="login">
						<div class="form-group mb-3">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<span class="fas fa-envelope"></span>
									</div>
								</div>
								<input type="text" class="form-control" :class="{'is-invalid': errors.email}" placeholder="Enter your email" v-model="user.email" ref="email" tabindex="1">
								<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
							</div>
						</div>
						<div class="form-group mb-3">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<span class="fas fa-lock"></span>
									</div>
								</div>
								<input type="password" class="form-control" :class="{'is-invalid': errors.password}" placeholder="Enter your password" v-model="user.password" tabindex="2">
								<span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
							</div>
						</div>
						<div class="form-group">
							<button method="submit" class="btn btn-primary btn-block">Sign In</button>
						</div>
                        <router-link class="text-center" to="/forgot-password">
                            <p>Forgot Password ?</p>
                        </router-link>
					</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Proj-Login",
        data() {
            return {
                user: {
                    email: "",
                    password: "",
                    device: "Web",
                },
                errors: [],
            };
        },
        mounted() {
            this.$refs.email.focus();
        },
        methods: {
            login() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("auth", { uri: "login/admin", data: vm.user })
				.then(function (response) {
					loader.hide();
                    
					vm.$store.dispatch("setUser", response.data.user);
                    vm.$store.dispatch("setToken", response.data.token);
                    vm.$store.dispatch("setPermissions", response.data.permissions);
                    vm.$router.push("/dashboard");
                    window.location.reload();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },
        },
    };
</script>

<style scoped>
    .card-body {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
