<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                    <i class="fa fa-home"></i>
                    Dashboard
                </li>
            </ol>
        </nav>

        <div class="content">
            <div class="container-fluid">
                <div class="row" v-if="$store.getters.user?.role?.role=='Admin'">
                    <div class="col-lg-3 col-6">
                        <div class="small-box bg-purple">
                            <div class="inner">
                                <h3>{{ total_users }}</h3>
                                <p>Total Users</p>
                            </div>
                            <div class="icon">
                                <i class="fas fa-users"></i>
                            </div>
                            <router-link to="/users" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="small-box bg-warning">
                            <div class="inner">
                                <h3>{{ total_projects }}</h3>
                                <p>Total Projects</p>
                            </div>
                            <div class="icon">
                                <i class="fas fa-list"></i>
                            </div>
                            <router-link to="/projects" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="small-box bg-teal">
                            <div class="inner">
                                <h3>{{ total_forms }}</h3>
                                <p>Total Forms</p>
                            </div>
                            <div class="icon">
                                <i class="fas fa-file"></i>
                            </div>
                            <router-link to="/Forms" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="small-box bg-danger">
                            <div class="inner">
                                <h3>{{ total_records }}</h3>
                                <p>Total Records</p>
                            </div>
                            <div class="icon">
                                <i class="fas fa-folder"></i>
                            </div>
                            <router-link to="/records" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header"><h3 class="card-title">Records</h3></div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Record No.</th>
                                            <th>
                                                Record Date & Time
                                            </th>
                                            <th>
                                                Project
                                            </th>
                                            <th>Form</th>
                                            <th>User</th>
                                            <th class="text-center">Uploaded at</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(record,key) in records" :key="key">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{record.record_no}}</td>
                                            <td>{{record.record_date_time}}</td>
                                            <td>{{record.project?.project}}</td>
                                            <td>{{record.form?.form_name}}</td>
                                            <td>{{record.user?.name}}</td>
                                            <td class="text-center">{{record.uploaded_at}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Dashboard",
        components: {},
        data() {
            return {
                total_users: 0,
                total_projects: 0,
                total_forms: 0,
                total_records: 0,
                users: [],
                meta: {
                    order_by: "desc",
                    keyword: "record_no",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },

                records: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.getCount();
            vm.index();
        },
        methods: {
            getCount() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "count" })
                    .then(function (response) {
                        loader.hide();
                        vm.total_users = response.data["users"];
                        vm.total_projects = response.data["projects"];
                        vm.total_forms = response.data["forms"];
                        vm.total_records = response.data["records"];
                        // vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateRecords", data: vm.meta })
                    .then(function (response) {
                        vm.records = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                        // vm.getForms();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>
