<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Pages</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Page</h3>
                                <h3 class="card-title" v-else>Update Page</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="page">Page<span class="text-danger"> *</span></label>

                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.page}" placeholder="Page" v-model="page.page" ref="page" />
                                            <span v-if="errors.page" class="invalid-feedback">{{ errors.page[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="description">Description<span class="text-danger"> *</span></label>
                                            <textarea class="form-control form-control-sm" rows="3" placeholder="Description" v-model="page.description" :class="{'is-invalid': errors.description}"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-danger" type="button" @click="discard()"><i class="fas fa-ban"></i> Discard</button>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Pages</h3>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" v-if="!meta.trashed" />
                                </div>
                                <div class="table-responsive table-responsive-sm">
                                    <table class="table table-bordered table-striped table-sm text-nowrap">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th @click="sort('page')">
                                                    Page
                                                    <span>
                                                        <i v-if="meta.keyword=='page' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='page' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th @click="sort('description')">
                                                    Description
                                                    <span>
                                                        <i v-if="meta.keyword=='description' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='description' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center">Status</th>
                                                <th class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(page,key) in pages" :key="key">
                                                <td class="text-center">{{meta.from+key}}</td>
                                                <td>{{page.page}}</td>
                                                <td>{{page.description}}</td>

                                                <td class="text-center">
                                                    <div class="card-tools">
                                                        <div class="custom-control custom-switch custom-switch-on-success custom-switch-off-danger">
                                                            <input type="checkbox" class="custom-control-input" :id="'page' + page.page_id" :checked="page.status" :value="page.status" @change="updatePageStatus(page)" />
                                                            <label class="custom-control-label" :for="'page' + page.page_id"></label>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td class="text-center">
                                                    <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Edit">
                                                        <i class="fas fa-pencil-alt" @click="edit(page)"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr v-if="pages.length <= 0">
                                                <td colspan="20" style="text-align: center;">No Records Found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    // import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Page",
        components: { Pagination },
        data() {
            return {
                status: true,
                // editor: ClassicEditor,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "page",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },

                page: {
                    page_id: "",
                    page: "",
                    description: "",
                },
                pages: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            edit(page) {
                let vm = this;
                (vm.status = false), (vm.page = page), vm.$refs.page.focus();
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginatePages", data: vm.meta })
                    .then(function (response) {
                        vm.pages = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addPage", data: vm.page };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Page is successfully created");

                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            discard() {
                let vm = this;
                vm.page.page = "";
                vm.page.description = "";
                vm.errors = [];
                vm.status = true;
                vm.index();
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updatePage", data: vm.page };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Page is successfully updated");
                        vm.discard();
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            updatePageStatus(page) {
                let vm = this;
                page.status = page.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deletePage", data: page })
                    .then(function () {})
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
