<template>
    <div class="hold-transition login-page">
        <div class="login-box">
            <div class="card">
                <div class="card-header text-center">
                    <img src="@/assets/logo.png" alt="Logo" height="80" />
                   
                </div>
                <div class="card-body">
                    <p class="login-box-msg">Kindly enter your email address</p>
                    <form autocomplete="off" @submit.prevent="forgotPassword">
						<div class="form-group mb-3">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<span class="fas fa-envelope"></span>
									</div>
								</div>
								<input type="text" class="form-control" :class="{'is-invalid': errors.email}" placeholder="Enter your email" v-model="user.email" ref="email" tabindex="1">
								<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
							</div>
						</div>
						<div class="form-group">
							<button method="submit" class="btn btn-primary btn-block">Submit</button>
						</div>
                        <router-link class="text-center" to="/login">
                            <p>Back</p>
                        </router-link>
					</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ForgotPassword.Index",
        data() {
            return {
                user: {
                    email: ""
                },
                errors: [],
            };
        },
        mounted() {
            this.$refs.email.focus();
        },
        methods: {
            forgotPassword() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("auth", { uri: "forgot_password", data: vm.user })
				.then(function (response) {
					loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    vm.user.email = "";
                    vm.errors = [];
                    vm.$router.push("/login");
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            }
        },
    };
</script>

<style scoped>
    .card-body {
        padding: 30px;
    }
</style>
