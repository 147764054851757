<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
               <li class="breadcrumb-item active">Role</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Role</h3>
                                <h3 class="card-title" v-else>Update Role</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="role">Role <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.role}" placeholder="Role" v-model="role.role" ref="role" />
                                            <span v-if="errors.role" class="invalid-feedback">{{ errors.role[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="description">Description</label>
                                            <textarea type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.description}" placeholder="Description" v-model="role.description"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-danger" type="button" @click="discard()"><i class="fas fa-ban"></i> Discard</button>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Roles</h3>
                             
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @click="search" @keyup="index" v-if="!meta.trashed"/>
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th @click="sort('role')">Role
                                                <span>
                                                    <i v-if="meta.keyword=='role' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='role' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>  
                                            </th>
                                            <th @click="sort('description')">Description
                                                <span>
                                                    <i v-if="meta.keyword=='description' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='description' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>  
                                            </th>
                                            <th class='text-center'>Status</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(role,key) in roles" :key="key">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{role.role}}</td>
                                            <td>{{role.description}}</td>
                                            <td class='text-center'> 
                                            <div class="card-tools">
                                            <div class="custom-control custom-switch custom-switch-on-success custom-switch-off-danger">
                                                <input type="checkbox" class="custom-control-input" :id="'role' + role.role_id" :checked="role.status" :value="role.status" @change="updateRoleStatus(role)" />
                                                <label class="custom-control-label" :for="'role' + role.role_id"></label>
                                            </div>
                                </div>
                                            </td>
                                            <td class="text-center" >
                                                <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Edit">
                                                    <i class="fas fa-pencil-alt" @click="edit(role)"></i>
                                                </button>
                                               
                                            </td>
                                           
                                        </tr>
                                        <tr v-if="roles.length <= 0">
                                            <td colspan="20" style="text-align: center;">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Role",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "role",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                role: {
                    role_id: "",
                    role: "",
                    description: "",
                },
                roles: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            edit(role) {
                let vm = this;
                (vm.status = false), (vm.role = role), vm.$refs.role.focus();
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateRoles", data: vm.meta })
                    .then(function (response) {
                        vm.roles = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
             updateRoleStatus(role) {
               
                let vm = this;
                role.status =  role.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", {uri:"deleteRole",data:role})
                    .then(function () {
                                       
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            // isTrashed() {
            //     let vm = this;
            //     vm.meta.trashed = !vm.meta.trashed;
            //     if(vm.meta.trashed)
            //         vm.meta.page = 1
            //     vm.index();
            // },

            search(){
               let vm = this;
               vm.meta.page = 1;
               vm.index();

            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addRole", data: vm.role };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Role is successfully created");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            discard() {
                let vm = this;
                vm.role.role = "";
                vm.role.description = "";
                vm.errors = [];
                vm.status = true;
                vm.index();
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateRole", data: vm.role };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Role is successfully updated");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            // deleteRole(role) {
            //     if (confirm("Do you want to continue... ?")) {
            //         let vm = this;
            //         let loader = vm.$loading.show();
            //         let uri = { uri: "deleteRole", data: role };
            //         vm.$store
            //             .dispatch("post", uri)
            //             .then(function () {
            //                 loader.hide();
            //                 vm.$store.dispatch("success", "Role is successfully deleted");
            //                 vm.discard();
            //             })
            //             .catch(function (error) {
            //                 loader.hide();
            //                 vm.errors = error.response.data.errors;
            //                 vm.$store.dispatch("error", error.response.data.message);
            //             });
            //     }
            // },
            
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
