<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Forms</li>

                <li class="breadcrumb-right">
                    <router-link to="/form/create" class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Add Form</router-link>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center"> 
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header"><h3 class="card-title">Forms</h3></div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group"><input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" /></div>
                                    </div>
                                    <div class="col-sm-12">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">#</th>
                                                    <th @click="sort('form_id')">Form Name
                                                         <span>
                                                            <i v-if="meta.keyword=='form_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='form_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th @click="sort('project_id')">
                                                        Project Name
                                                        <span>
                                                            <i v-if="meta.keyword=='project_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='project_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th @click="sort('language_id')">
                                                        Language
                                                        <span>
                                                            <i v-if="meta.keyword=='language_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='language_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th class="text-center">Created at</th>
                                                    <th class="text-center">Updated at</th>
                                                   
                                                    <th class="text-center">Status</th>

                                                    <th class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(form,key) in forms" :key="key">
                                                   <td class="text-center">{{meta.from+key}}</td>
                                                    <td>{{form.form_name}}</td>
                                                    <td>{{form.project?.project}}</td>
                                                    <td>{{form.language?.language}}</td>
                                                    <td class="text-center">{{form.created_at}}</td>
                                                    <td class="text-center">{{form.updated_at}}</td>
                                                    <td class="text-center">
                                                        <div class="card-tools">
                                            <div class="custom-control custom-switch custom-switch-on-success custom-switch-off-danger">
                                                <input type="checkbox" class="custom-control-input" :id="'form' + form.form_id" :checked="form.status" :value="form.status" @change="updateFormStatus(form)" />
                                                <label class="custom-control-label" :for="'form' + form.form_id"></label>
                                            </div>
                                </div>
                                                    </td>

                                                    <td class="text-center">
                                                        <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="edit"><i class="fas fa-edit" @click="edit(form)"></i></button>
                                                        
                                                    </td>
                                                </tr>

                                                <tr v-if="forms.length <= 0">
                                                    <td colspan="20" style="text-align: center;">No Records Found</td>
                                                </tr>

                                                <!--v-if-->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Forms.Index",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "form_name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },

                forms: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            edit(form) {
                this.$router.push("/forms/" + form.form_id + "/edit");
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateForms", data: vm.meta })
                    .then(function (response) {
                        vm.forms = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                if (vm.meta.trashed) vm.meta.page = 1;
                vm.index();
            },

            deleteForm(form) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteForm", data: form };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Form is successfully deleted");
                            vm.index();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
             updateFormStatus(form) {

                let vm = this;
                form.status =  form.status == 1 ? 0 : 1;

                vm.$store
                    .dispatch("post", {uri:"deleteForm",data:form})
                    .then(function () {

                    })
                    .catch(function (error) {

                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
