<template>
<footer class="main-footer">
<!-- <div class="float-right d-none d-sm-block">Powered By <a href="https://aeriesys.com" target="_blank">Aeriesys Tech</a></div> -->
<strong>Copyright &copy; {{ new Date().getFullYear() }} <a target="_blank" href="javascript:void(0)">Aditya Birla Education Trust</a>.</strong> All rights reserved.
</footer>
</template>

<script>
	export default {
		name: 'Footer'
	}
</script>