<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item"><router-link to="/forms">Forms</router-link></li>
                <li class="breadcrumb-item active" v-if="status">Add Form</li>
                <li class="breadcrumb-item active" v-else>Update Form</li>
                <!-- <li class="breadcrumb-right">
                    <router-link to="/users/create" class="btn btn-sm btn-success">
                        Add New
                    </router-link>
                </li> -->
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card mb-4">
                            <div class="card-header d-flex justify-content-between">
                                <h3 class="card-title">Form</h3>
                                <button type="button" class="btn btn-sm btn-primary ml-auto" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-plus"></i> Add Message</button>
                            </div>
                            <div class="card-body row">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="description">Form <span class="text-danger"> *</span></label>
                                        <input class="form-control form-control-sm" type="text" placeholder="Form" :class="{ 'is-invalid': errors.form_name }" v-model="form.form_name" ref="form_name" />
                                        <span v-if="errors.form_name" class="invalid-feedback">{{ errors.form_name[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <label for="description">Project <span class="text-danger"> *</span> </label>
                                    <div class="form-group">
                                        <!-- <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.project_id }" v-model="form.project_id">
                                            <option value="" selected>Select Project</option>
                                            <option v-for="(project,key) in projects" :key="key" :value="project.project_id">{{ project.project}}</option>
                                        </select> -->
                                        <search
                                                :class="{ 'is-invalid': errors.project_id }"
                                                :customClass="{ 'is-invalid': errors.project_id }"
                                                :initialize="form.project_id"
                                                id="project_id"
                                                label="project"
                                                placeholder="Select Project"
                                                :data="projects"
                                                @input=" project1 => form.project_id = project1"
                                                ref="project"
                                            >
                                            </search>
                                        <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <label for="description">Language <span class="text-danger"> *</span></label>
                                    <div class="form-group">
                                        <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.language_id }" v-model="form.language_id">
                                            <option value="" selected>Select Language</option>
                                            <option v-for="(language,key) in languages" :key="key" :value="language.language_id">{{ language.language}}</option>
                                        </select>
                                        <span v-if="errors.language_id" class="invalid-feedback">{{ errors.language_id[0] }}</span>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="table-responsive table-responsive-sm">
                                        <table class="table table-bordered table-striped table-sm text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Field Name <span class="text-danger"> *</span></th>
                                                    <th>Data Type <span class="text-danger"> *</span></th>
                                                    <th>Length <span class="text-danger"> *</span></th>
                                                    <th>Category</th>
                                                    <th>Values</th>
                                                    <th>Required?<span class="text-danger"> *</span></th>
                                                    <th>Order<span class="text-danger"> *</span></th>
                                                    <th>Score</th>
                                                    <th>Display</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-center" @keyup="modifyFieldKey">
                                                        <input type="text" class="form-control form-control-sm" placeholder="Field Name" :class="{ 'is-invalid': errors.field_name }" v-model="form_field.field_name " />
                                                        <span v-if="errors.field_name" class="invalid-feedback">{{ errors.field_name[0] }}</span>
                                                    </td>
                                                    <td>
                                                        <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.field_type }" v-model="form_field.field_type">
                                                            <option value="" selected>Select Data Type</option>
                                                            <option value="text">Text</option>
                                                            <option value="number">Number</option>
                                                            <option value="tel">Phone Number</option>
                                                            <option value="textarea">Text Area</option>
                                                            <option value="date">Date</option>
                                                            <option value="select">Select</option>
                                                            <option value="dd">DD</option>
                                                        </select>
                                                        <span v-if="errors.field_type" class="invalid-feedback">{{ errors.field_type[0] }}</span>
                                                    </td>
                                                    <td>
                                                        <input type="number" class="form-control form-control-sm" placeholder="Length" :class="{ 'is-invalid': errors.field_length }" v-model="form_field.field_length" />
                                                        <span v-if="errors.field_length" class="invalid-feedback">{{ errors.field_length[0] }}</span>
                                                    </td>
                                                    <td>
                                                        <select :disabled="form_field.field_type!='dd'" class="form-control form-control-sm" :class="{ 'is-invalid': errors.category_type_id }" v-model="form_field.category_type_id" @change="getCategories()">
                                                            <option value="" selected>Select</option>
                                                            <option v-for="(category_type,key) in category_types" :key="key" :value="category_type.category_type_id">{{ category_type.category_type}}</option>
                                                        </select>
                                                        <span v-if="errors.category_type_id" class="invalid-feedback">{{ errors.category_type_id[0] }}</span>
                                                    </td>
                                                    <td>
                                                        <input type="text" :disabled="form_field.field_type!='select'" class="form-control form-control-sm" placeholder="Value" :class="{ 'is-invalid': errors.field_values }" v-model="form_field.field_values" />
                                                        <span v-if="errors.field_values" class="invalid-feedback">{{ errors.field_values[0] }}</span>
                                                    </td>
                                                    <td>
                                                        <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.is_required }" v-model="form_field.is_required">
                                                            <option value="" selected>Select </option>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                        <span v-if="errors.is_required" class="invalid-feedback">{{ errors.is_required[0] }}</span>
                                                    </td>
                                                    <td>
                                                        <input type="number" class="form-control form-control-sm" placeholder="Order" :class="{ 'is-invalid': errors.order }" v-model="form_field.order" />
                                                        <span v-if="errors.order" class="invalid-feedback">{{ errors.order[0] }}</span>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control form-control-sm" placeholder="Score" :class="{ 'is-invalid': errors.score }" v-model="form_field.score" />
                                                        <span v-if="errors.score" class="invalid-feedback">{{ errors.score[0] }}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <input class="mt-10" type="checkbox" :class="{ 'is-invalid': errors.is_display }" v-model="form_field.is_display" >
                                                       
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="formStatus" class="btn btn-sm btn-success" type="button" @click.prevent="addRow()">
                                                            <i class="fas fa-plus"></i>
                                                        </button>
                                                        <button v-else class="btn btn-sm btn-primary" type="button" @click.prevent="updateRow()">
                                                            <i class="fas fa-pencil-alt"></i>
                                                        </button>
                                                    </td>
                                                </tr>

                                                <tr v-for="(form_field, key) in form.form_fields" :key="key">
                                                    <td>{{form_field.field_name}}</td>
                                                    <td>{{form_field.field_type}}</td>
                                                    <td>{{form_field.field_length}}</td>
                                                    <td>{{form_field.category_type}}</td>
                                                    <td>{{ form_field.field_values?.substring(0,30) }}</td>
                                                    <td>{{form_field.is_required==1 ? 'Yes' : 'No'}}</td>
                                                    <td>{{form_field.order}}</td>
                                                    <td>{{ form_field.score }}</td>
                                                    <td>{{ form_field.is_display==true ? 'Yes' : 'No' }}</td>
                                                    <td>
                                                        <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Edit" @click="editRow(form_field,key)">
                                                            <i class="fas fa-pencil-alt"></i>
                                                        </button>
                                                        <button class="btn btn-outline-danger btn-sm" type="button" @click="deleteRow(form_field,key)">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/forms" class="btn btn-sm btn-outline-danger"><i class="fas fa-ban"></i> Discard</router-link>
                                    <button class="btn btn-sm btn-outline-success" type="button" @click.prevent="submitForm()">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Messages</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">#</th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Message</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(message, key) in form.messages" :key="key">
                                                   <td class="text-center">{{key+1}}</td>
                                                    <td>{{message.from}}</td>
                                                    <td>{{message.to}}</td>
                                                    <td>{{message.message}}</td>
                                                    <td class="text-center">
                                                        <button class="btn btn-xs btn-outline-danger" data-toggle="tooltip" data-placement="bottom" title="delete" @click="deleteMessge(message)"><i class="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                                <tr v-if="form.messages?.length <= 0">
                                            <td colspan="5" style="text-align: center;">No Records Found</td>
                                        </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

     <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="messageModal"> 
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Add Message</h5>
                <button type="button" id="close" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>From<span class="text-danger"> *</span></label>
                            <input type="text" class="form-control form-control-sm" placeholder="From" :class="{'is-invalid': errors.from}" v-model="message.from">
                            <span v-if="errors.from" class="invalid-feedback">{{ errors.from[0] }}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>To<span class="text-danger"> *</span></label>
                            <input type="text" class="form-control form-control-sm" placeholder="To" :class="{'is-invalid': errors.to}" v-model="message.to">
                            <span v-if="errors.to" class="invalid-feedback">{{ errors.to[0] }}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Message<span class="text-danger"> *</span></label>
                            <textarea type="text" class="form-control form-control-sm" placeholder="Message" :class="{'is-invalid': errors.message}" v-model="message.message"></textarea>
                            <span v-if="errors.message" class="invalid-feedback">{{ errors.message[0] }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <form id="myForm">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary" @click="addMessage()">Submit</button>
                     </form>
            </div>
       
            </div>
        </div>
    </div> 

    
</template>

<script>
import Search from "@/components/Search.vue";
    export default {
        name: "Form.Create",
        components: {Search},
        data() {
            return {
                status: true,

                form: {
                    form_id: "",
                    form_name: "",
                    project_id: "",
                    language_id: "",
                    form_fields: [],
                    deleted_form_fields: [],
                    messages:[],
                },
                form_field: {
                    form_field_id: "",
                    field_name: "",
                    field_type: "",
                    field_length: "",
                    field_key: "",
                    category_type_id: "",
                    category_type: "",
                    field_values: "",
                    key: "",
                    is_required: "0",
                    skip_to: "0",
                    skip_value: "0",
                    order:"1",
                    score: "",
                    default_value: "",
                    is_display:false,
                },
                message:{
                    form_id:"",
                    from: "",
                    to: "",
                    message: "",
                },
                
                formStatus: true,

                errors: [],
                projects: [],
                category_types:[],
                languages: [],
            };
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.message.form_id =to.params.form_id;
                if (to.name == "Forms.Create") {
                    vm.$refs.form_name.focus();
                } 
                else {
                    vm.status = false;
                    let uri = { uri: "viewForm", data: { form_id: to.params.form_id } };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.form = response.data.data;
                        vm.form.deleted_form_fields = [];
                        vm.getMessages();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },
        mounted() {
            let vm = this;
            vm.getProject();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.addForms();
                } else {
                    vm.updateForms();
                }
            },
            getProject() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getUserProjects" })
                .then(function (response) {
                    vm.projects = response.data.data;
                    vm.getLanguage();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getLanguage() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getLanguages" })
                .then(function (response) {
                    vm.languages = response.data.data;
                    vm.getCategoryTypes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getCategoryTypes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getCategoryTypes" })
                .then(function (response) {
                    vm.category_types = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getCategories() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getCategories", data:vm.form_field })
                .then(function (response) {
                    let categories = response.data.data;
                    vm.form_field.field_values = "";
                    categories.forEach(category => {
                        if(vm.form_field.field_values==''){
                            vm.form_field.field_values =  category.category
                        }
                        else{
                            vm.form_field.field_values =  vm.form_field.field_values +','+ category.category
                        }
                    });
                    let category_type = vm.category_types.filter(category_type => {
                        return category_type.category_type_id == vm.form_field.category_type_id
                    })
                    if(category_type.length!=0) {
                        vm.form_field.category_type = category_type[0].category_type;
                    }
                })
                .catch(function (error) {
                    vm.form_field.field_values = "";
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addForms() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "addForm", data: vm.form })
                .then(function () {
                    vm.$store.dispatch("success", "Forms is successfully created");
                    vm.$router.push("/forms");
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            updateForms() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "updateForm", data: vm.form })
                .then(function () {
                    vm.$store.dispatch("success", "Forms is successfully updated");
                    vm.$router.push("/forms");
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addRow() {
                let vm = this;
                
                vm.errors = [];
                if (vm.form_field.field_name == "" || vm.form_field.field_type == "" || vm.form_field.field_length == "" || vm.form_field.field_key == "" || vm.form_field.order == "" || vm.form_field.is_required == "") {
                    
                    if(vm.form_field.field_name == ""){
                        vm.errors.field_name = ["Field name cannot be empty"];
                    }
                    if(vm.form_field.field_type == ""){
                        vm.errors.field_type = ["Field type cannot be empty"];
                    }
                    if(vm.form_field.field_length == ""){
                        vm.errors.field_length = ["Field length cannot be empty"];
                    }
                    if(vm.form_field.field_key == ""){
                        vm.errors.field_key = ["Field key cannot be empty"];
                    }
                    if(vm.form_field.order == ""){
                        vm.errors.order = ["Field order cannot be empty"];
                    }
                    if(vm.form_field.is_required == ""){
                        vm.errors.is_required = ["is required cannot be empty"];
                    }
                }
                else if(vm.form_field.field_type == 'select' && (vm.form_field.field_values == '' || vm.form_field.field_values == null)) {
                    
                    vm.errors.field_values = ["Field Value cannot be empty"];
                }
                else if(vm.form_field.field_type == 'select' && (vm.form_field.score == '' || vm.form_field.score == null)) {
                    vm.errors.score = ["Score cannot be empty"];
                }
                else if(vm.form_field.field_type == 'dd' && (vm.form_field.field_values == '' || vm.form_field.category_type == "")) {
                    
                    vm.errors.field_values = ["Field Value cannot be empty"];
                }
                else {
                    if(vm.form_field.field_type == 'select' && vm.form_field.score != ''){
                        let score = vm.form_field.score.split(",")
                        let values = vm.form_field.field_values.split(',')
                        console.log(score)
                        console.log(values)
                        if(score.length != values.length){
                            vm.errors.score = ["Scores should match with values"];
                        }else{
                            vm.add()
                        }
                    }else{
                        vm.add()
                    }
                }
            },
            add(){
                let vm = this
                let data = {
                    form_field_id: "",
                    field_name: vm.form_field.field_name,
                    field_type: vm.form_field.field_type,
                    field_length: vm.form_field.field_length,
                    field_key: vm.form_field.field_key,
                    category_type_id: vm.form_field.category_type_id,
                    category_type: vm.form_field.category_type,
                    field_values: vm.form_field.field_values,
                    is_required: vm.form_field.is_required,
                    skip_to: vm.form_field.skip_to,
                    skip_value: vm.form_field.skip_value,
                    order: vm.form_field.order,
                    score: vm.form_field.score,
                    default_value: vm.form_field.default_value,
                    is_display: vm.form_field.is_display,
                }
                vm.form.form_fields.push(data);
                vm.discardForm();
            },

            editRow(forms, key) {
                let vm = this;
                vm.form_field = forms;
                vm.formStatus = false;
                vm.form_field.key = key;
                vm.errors = []
            },

            updateRow() {
                let vm = this;
                vm.errors = [];
                if (vm.form_field.field_name == "" || vm.form_field.field_type == "" || vm.form_field.field_length == "" || vm.form_field.field_key == "" || vm.form_field.order == "" || String(vm.form_field.is_required) == "") {
                    if(vm.form_field.field_name == ""){
                        vm.errors.field_name = ["Field name cannot be empty"];
                    }
                    if(vm.form_field.field_type == ""){
                        vm.errors.field_type = ["Field type cannot be empty"];
                    }
                    if(vm.form_field.field_length == ""){
                        vm.errors.field_length = ["Field length cannot be empty"];
                    }
                    if(vm.form_field.field_key == ""){
                        vm.errors.field_key = ["Field key cannot be empty"];
                    }
                    if(vm.form_field.order == ""){
                        vm.errors.order = ["Field order cannot be empty"];
                    }
                    if(vm.form_field.is_required == ""){
                        vm.errors.is_required = ["is required cannot be empty"];
                    }
                }
                else if(vm.form_field.field_type == 'select' && (vm.form_field.field_values == '' || vm.form_field.field_values == null)) {
                    vm.errors.field_values = ["Field Value cannot be empty"];
                }
                else if(vm.form_field.field_type == 'select' && (vm.form_field.score == '' || vm.form_field.score == null)) {
                    vm.errors.score = ["Score cannot be empty"];
                }
                else if(vm.form_field.field_type == 'dd' && (vm.form_field.field_values == '' || vm.form_field.category_type == "")) {
                    vm.errors.field_values = ["Field Value cannot be empty"];
                }
                else {
                    if(vm.form_field.field_type == 'select' && vm.form_field.score != ''){
                        let score = vm.form_field.score.split(",")
                        let values = vm.form_field.field_values.split(',')
                        console.log(score)
                        console.log(values)
                        if(score.length != values.length){
                            vm.errors.score = ["Scores should match with values"];
                        }else{
                            vm.update()
                        }
                    }else{
                        vm.update()
                    }
                }
            },

            update(){
                let vm = this
                let data = {
                    form_field_id: vm.form_field.form_field_id,
                    field_name: vm.form_field.field_name,
                    field_type: vm.form_field.field_type,
                    field_length: vm.form_field.field_length,
                    field_key: vm.form_field.field_key,
                    category_type_id: vm.form_field.category_type_id,
                    category_type: vm.form_field.category_type,
                    field_values: vm.form_field.field_values,
                    is_required: vm.form_field.is_required,
                    skip_to: vm.form_field.skip_to,
                    skip_value: vm.form_field.skip_value,
                    order: vm.form_field.order,
                    score: vm.form_field.score,
                    default_value: vm.form_field.default_value,
                    is_display: vm.form_field.is_display,
                }
                vm.form.form_fields.splice(vm.form_field.key, 1, data);
                vm.discardForm();
            },

            deleteRow(forms, key) {
                let vm = this;
                vm.form.deleted_form_fields.push(forms), vm.form.form_fields.splice(key, 1);
                vm.discardForm()
            },

            discardForm() {
                let vm = this;
                vm.form_field.field_name = "";
                vm.form_field.field_type = "";
                vm.form_field.field_length = "";
                vm.form_field.field_key = "";
                vm.form_field.category_type_id = "";
                vm.form_field.category_type = "";
                vm.form_field.field_values = "";
                vm.form_field.is_required = "0";
                vm.form_field.skip_to = "0";
                vm.form_field.skip_value = "0";
                vm.form_field.order="";
                vm.form_field.score="";
                vm.form_field.default_value="";
                vm.form_field.is_display=false;
                vm.formStatus = true;
                vm.errors = [];
            },

            modifyFieldKey() {
                let vm = this;
                vm.form_field.field_key = vm.form_field.field_name.replace(/ /g, "_");
            },
            addMessage(){
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addScoreMessage", data: vm.message };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Message is successfully created");
                        vm.getMessages();
                        let modal=vm.$refs.messageModal
                        modal.style.display="none";
                        document.getElementById('close').click();
                        vm.message.form_id = ''
                        vm.message.from = ''
                        vm.message.to = ''
                        vm.message.message = ''
                        vm.errors = []
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response?.data?.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getMessages() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getScoreMessages", data: vm.message })
                    .then(function (response) {
                        vm.form.messages = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            deleteMessge(message) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteScoreMessage", data: message };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Message is successfully deleted");
                            vm.getMessages();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
          
        },
    };
</script>
<style scoped>
.mt-10{
    margin-top: 10px;
}
</style>
