<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">
                    Keywords
                </li>

                <li class="breadcrumb-right">
                    <router-link to="/keywords/create" class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Add Keyword</router-link>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center"> 
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header"><h3 class="card-title">Keywords</h3></div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group"><input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" /></div>
                                    </div>
                                    <div class="col-sm-12">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                               
                                                  <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th @click="sort('keyword')">Keyword 
                                                        <span>
                                                    <i v-if="meta.keyword=='keyword' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='keyword' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>  
                                                    </th>
                                                    <th @click="sort('regional_word')">Regional Word
                                                         <span>
                                                    <i v-if="meta.keyword=='regional_word' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='regional_word' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>  
                                                    </th>
                                                    <th class="text-center">Created at</th>
                                                    <th class="text-center">Updated at</th>
                                                    <th class=text-center>Action</th>
                                                </tr>
                                            </thead>
                                            
                                            <tbody>
                                                 <tr v-for="(keyword,key) in keywords" :key="key">
                                                    <td>{{meta.from+key}}</td>
                                                    <td>{{ keyword.keyword }}</td>
                                                    <td>
                                                        {{ keyword.regional_word }}
                                                    </td>
                                                    <td class="text-center">{{keyword.created_at}}</td>
                                                    <td class="text-center">{{keyword.updated_at}}</td>
                                                     <td class="text-center">
                                                        <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="edit"><i class="fas fa-edit" @click="edit(keyword)"></i></button>
                                                        <button class="btn btn-xs btn-outline-danger" data-toggle="tooltip" data-placement="bottom" title="delete"><i class="fas fa-trash" @click=" deleteKeyword(keyword)"></i></button>
                                                     </td>
                                                </tr>
                                               
                                                <tr v-if="keywords.length <= 0">
                                                    <td colspan="20" style="text-align: center;">No Records Found</td>
                                                </tr>

                                         
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Keywords.Index",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "keyword",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },

                keywords: [],
                languages: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
           
        },
            
        methods: {
            edit(keyword) {
                this.$router.push("/keywords/" + keyword.keyword_id + "/edit");
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
             

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateKeywords", data: vm.meta })
                    .then(function (response) {
                        vm.keywords = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                if (vm.meta.trashed) vm.meta.page = 1;
                vm.index();
            },

            deleteKeyword(keyword) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteKeyword", data: keyword };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Keyword is successfully deleted");
                            vm.index();
                            vm.trashed();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
      
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
