<template>
	<div class="wrapper" v-if="$store.getters.user">
		<Header></Header>
		<Menu></Menu>
		<router-view/>
		<Footer></Footer>
  </div>
	<div class="wrapper" v-else>
		<router-view/>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Menu from '@/components/Menu.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		name: 'App',
		components: { Header, Menu, Footer },
		created() {
     
            //Read the status information in sessionStorage when the page is loaded
            if (sessionStorage.getItem("user") ) {
                this.$store.dispatch('setUser', JSON.parse(sessionStorage.getItem("user")))
                sessionStorage.removeItem('user')
            }
            if (sessionStorage.getItem("token") ) {
                this.$store.dispatch('setToken', sessionStorage.getItem("token"))
                sessionStorage.removeItem('token')
            }
             if (sessionStorage.getItem("permissions") ) {
                this.$store.dispatch('setPermissions', JSON.parse(sessionStorage.getItem("permissions")))
                sessionStorage.removeItem('permissions')
            }
            //Save the information in vuex to sessionStorage when the page is refreshed
            window.addEventListener("beforeunload",()=>{
                
                sessionStorage.setItem("user",  JSON.stringify(this.$store?.getters?.user))
                sessionStorage.setItem("token",  this.$store?.getters?.token)
                sessionStorage.setItem("permissions",  JSON.stringify(this.$store?.getters?.permissions))
               
            })
        }
	}
</script>
