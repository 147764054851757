<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Records</li>
                <li class="breadcrumb-right">
                    <!-- <button type="button" class="btn btn-sm btn-success" >Report</button> -->
                <button @click="generateReport" type="button" class="btn btn-sm btn-success">
                    <i class="fa fa-file-excel"></i> Report
                </button>

                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header d-flex justify-content-between">
                                <h3 class="card-title">Records</h3>
                                <div class="text-end ml-auto">
                                     <button type="button" class="btn btn-sm btn-primary" @click="search()">Search</button>
                                                  <button type="button" class="btn btn-sm btn-danger" @click="reset()">Reset</button>

                                </div>

                            
                            </div>
                            <div class="card-body">
                                <div class="row">
                                      <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Project <span class="text-danger"> *</span></label>

                                            <search
                                                :class="{ 'is-invalid': errors.project_id }"
                                                :customClass="{ 'is-invalid': errors.project_id }"
                                                :initialize="meta.project_id"
                                                id="project_id"
                                                label="project"
                                                placeholder="Select Project"
                                                :data="projects"
                                                @input="changeProject($event)"
                                            >
                                            </search>
                                            <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label>Form <span class="text-danger"> *</span> </label>

                                            <search
                                                :class="{ 'is-invalid': errors.form_id }"
                                                :customClass="{ 'is-invalid': errors.form_id }"
                                                :initialize="meta.form_id"
                                                id="form_id"
                                                label="form_name"
                                                placeholder="Select Form"
                                                :data="forms"
                                                @input="form => meta.form_id = form"
                                            >
                                            </search>
                                            <span v-if="errors.form_id" class="invalid-feedback">{{ errors.form_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>From Date</label>
                                            <input type="date" class="form-control form-control-sm" v-model="meta.from_date" />
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>To Date</label>
                                            <input type="date" class="form-control form-control-sm" v-model="meta.to_date" />
                                            <!--v-if-->
                                        </div>
                                    </div>
                                  
                                    <div class="col-sm-2">
                                        <label>User</label>

                                        <search
                                            :class="{ 'is-invalid': errors.user_id }"
                                            :customClass="{ 'is-invalid': errors.user_id }"
                                            :initialize="meta.user_id"
                                            id="user_id"
                                            label="name"
                                            placeholder="Select User"
                                            :data="users"
                                            @input="user => meta.user_id = user"
                                        >
                                        </search>
                                    </div>
                                    <div class="col-sm-12 mt-3">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th @click="sort('record_no')">
                                                        Record No.
                                                        <span>
                                                            <i v-if="meta.keyword=='record_no' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='record_no' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th @click="sort('record_date_time')">
                                                        Created at
                                                        <span>
                                                            <i v-if="meta.keyword=='record_date_time' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='record_date_time' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th @click="sort('project_id')">
                                                        Project
                                                        <span>
                                                            <i v-if="meta.keyword=='project_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='project_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th @click="sort('form_id')">
                                                        Form
                                                        <span>
                                                            <i v-if="meta.keyword=='form_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='form_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th @click="sort('user_id')">
                                                        User
                                                        <span>
                                                            <i v-if="meta.keyword=='user_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='user_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th class="text-center">Score</th>
                                                    <th class="text-center">Uploaded at</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(record,key) in records" :key="key">
                                                    <td class="text-center">{{meta.from+key}}</td>
                                                    <td>{{record.record_no}}</td>
                                                    <td>{{record.record_date}}</td>
                                                    <td>{{record.project?.project}}</td>
                                                    <td>{{record.form?.form_name}}</td>
                                                    <td>{{record.user?.name}}</td>
                                                    <td class="text-center">{{ record.score }}</td>
                                                    <td class="text-center">{{record.uploaded_at}}</td>
                                                    <td class="text-center">
                                                        <a target="_blank" :href="$store.getters.pdfUrl+'record?record_id='+record.record_id+'&display_type=pdf'" class="btn btn-sm btn-outline-success">
                                                            <i class="fa fa-file-pdf"></i>
                                                        </a>
                                                        <a target="_blank" :href="$store.getters.pdfUrl+'record?record_id='+record.record_id+'&display_type=excel'" class="btn btn-sm btn-outline-success">
                                                            <i class="fa fa-file-excel"></i>
                                                        </a>
                                                        <button v-if="$store.getters.user?.role?.role=='Admin'" class="btn btn-xs btn-outline-danger" data-toggle="tooltip" data-placement="bottom" title="delete"><i class="fas fa-trash" @click=" deleteRecord(record)"></i></button>
                                                    </td>
                                                </tr>
                                                <tr v-if="records.length <= 0">
                                                    <td colspan="20" style="text-align: center;">No Records Found</td>
                                                </tr>

                                                <!--v-if-->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    let Search = require("@/components/Search.vue").default;
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Records",
        components: { Search, Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "desc",
                    keyword: "record_no",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                    from_date: "",
                    to_date: "",
                    project_id:"",
                    form_id:"",
                    user_id: "",
                    login_user_id:this.$store.getters.user.user_id
                },
                forms: [],
                projects: [],
                users: [],
                records: [],
                errors: [],
            };
        },

        mounted() {
            let vm = this;
            vm.getProjects()
            vm.index();
        },

        methods: {
            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateRecords", data: vm.meta })
                .then(function (response) {
                    vm.records = response?.data?.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getProjects() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getUserProjects" })
                .then(function (response) {
                    vm.projects = response.data.data;
                    vm.getUsers();
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getUsers() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getUsers" })
                .then(function (response) {
                    vm.users = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            changeProject(project_id) {
                let vm = this;
                if(Number.isInteger(project_id)) {
                    vm.meta.project_id = project_id;
                    vm.getForms()
                }
            },

            getForms() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getForms",data:vm.meta })
                .then(function (response) {
                    vm.forms = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteRecord(record) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteRecord", data: record };
                    vm.$store.dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Record is successfully deleted");
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            generateReport() {
                let vm = this;
                vm.errors = [];
                if(vm.meta.project_id=='')
                {
                    Object.assign(vm.errors,{project_id:['Project field is required']})
                }
                if(vm.meta.form_id=='')
                {
                    Object.assign(vm.errors,{form_id:['Form field is required']})
                }
                if(vm.meta.project_id!='' && vm.meta.form_id!='')
                {
                    vm.errors = [];
                    let loader = vm.$loading.show();
                    vm.$store.dispatch("post", { uri: "mail_records",data:vm.meta })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Export started, please wait for sometime");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }

                // if(vm.meta.project_id!='' && vm.meta.form_id!='')
                // {
                //     vm.errors = [];
                //     let url = vm.$store.getters.pdfUrl+'export_records?project_id='+vm.meta.project_id+'&form_id='+vm.meta.form_id+'&from_date='+vm.meta.from_date+'&to_date='+vm.meta.to_date+'&user_id='+vm.meta.user_id+'&login_user_id='+vm.$store.getters.user.user_id+'&display_type=excel';
                //     window.open(url)
                // }
            },  

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },

            reset(){
                let vm = this;
                vm.meta.from_date='';
                vm.meta.to_date='';
                vm.meta.user_id='';
                vm.meta.project_id='';
                vm.meta.form_id='';
                vm.forms = [];
                vm.index();
            },
        },
    };
</script>
